import React, { useContext } from "react";
import DefaultPalette from "../../theme/palette";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import {
  countTotalWeeks,
  getFormattedDate,
  getMicrocycleNumber,
  getRandomNumber,
} from "../../resources/simpleFunction";
import { Role } from "../../utils/enums";
import { useMainContext } from "../../context/MainContext";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

interface Props {
  dataPhases: any;
  calendarData?: any[];
  onPhaseClick: (phase: any) => void;
}

const PhaseTable = (props: Props): React.ReactElement => {
  const { dataPhases, calendarData, onPhaseClick } = props;
  const navigate = useNavigate();
  const { i18n } = useContext(LanguageContext);
  const { currentProfile } = useMainContext();

  const spanSum = calendarData ? countTotalWeeks(calendarData) : 0;
  const weeks = calendarData?.flatMap((monthData) => monthData.weeks) ?? [];

  const palette = DefaultPalette("dark", "custom");

  const tableRef = useRef<HTMLTableElement>(null);
  const [tableWidth, setTableWidth] = useState<number>(0);

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth);
    }
  }, [tableRef.current]);

  const onRowPress = (microcycle: any) => {
    const mcNumber = calendarData
      ? getMicrocycleNumber(calendarData, microcycle?.start_date)
      : null;

      console.log("mcNumb", mcNumber)

    navigate("/weeklyPlan", {
      state: {
        weekStartDate: getFormattedDate(microcycle?.start_date),
        weekEndDate: getFormattedDate(microcycle?.end_date),
        mc: mcNumber,
        allWeeks: JSON.stringify(calendarData),
      },
    });
  };

  const headerPhases = [
    {
      text: i18n.plans.headerPhase.phase,
      height: "40px",
    },
    {
      text: i18n.plans.headerPhase.mesocycle,
      height: "40px",
    },
    {
      text: i18n.plans.headerPhase.microcycle,
      height: "40px",
    },
  ];

  const cellWidth: number = tableWidth > 0 && spanSum > 0 ? tableWidth / spanSum : 0;

  const styles: any = {
    headerCell: {
      border: `1px solid ${palette.custom.background}`,
      backgroundColor: palette.custom.lightGray,
      padding: "5px",
      minWidth: "106px",
      position: "relative",
    },
    text: {
      color: palette.custom.white,
      fontSize: "0.7rem",
      fontWeight: "normal",
      textAlign: "center",
      padding: "0px",
    },
    headerText: {
      color: palette.custom.white,
      fontWeight: "bold",
    },
    cellStyle: {
      height: "40px",
      width: `${cellWidth}px`,
      border: `0.3px solid ${palette.custom.lightGray}`,
    },
    noPhases: {
      color: palette.custom.white,
      fontWeight: "bold",
      textAlign: "center",
      padding: "10px",
      fontSize: "1rem",
    },
    table: {
      borderCollapse: "collapse",
      padding: "0px",
      width: "100%",
    },
    mainBox: {
      display: "flex",
      flexDirection: "row",
    },
    insideBox: {
      width: "100%",
    },
  };

  function mapRows(weeks: any[], phases: any[], cellWidth: number) {
    const phaseRow: any[] = [];
    const subphaseRow: any[] = [];
    const microcycleRow: any[] = [];
    let skipToIndex = 0;

    for (let i = 0; i < weeks.length; i++) {
      if (i < skipToIndex) {
        continue;
      }

      const week = weeks[i];
      const startDate = week.week_start_date.split("T")[0];
      const phase = phases.find((p: any) => p.start_date === startDate);

      if (phase) {
        const phaseEndIndex = weeks.findIndex(
          (w) => w.week_end_date.split("T")[0] === phase.end_date
        );
        const phaseColspan = phaseEndIndex - i + 1;

        phaseRow.push(
          <td
            key={`phase-${phase.id}-${getRandomNumber(1, 150)}`}
            colSpan={phaseColspan}
            style={{
              ...styles.cellStyle,
            width: `${cellWidth * phaseColspan}px`,
              cursor:
                currentProfile?.role === Role.Coach ? "pointer" : "default",

            }}
            onClick={() =>
              currentProfile?.role === Role.Coach ? onPhaseClick(phase) : {}
            }
          >
            <Typography
              noWrap
              sx={{
                ...styles.text,
                width: `${cellWidth * phaseColspan-3}px`,
                // maxWidth: `${cellWidth * phaseColspan}px`,
              }}
            >
              {phase.name}
            </Typography>
          </td>
        );

        if (phase.subphases && phase.subphases.length > 0) {
          let remainingColspan = phaseColspan;

          phase.subphases.forEach((subphase: any) => {
            const subColspan = subphase.microcycles.length || 1;
            remainingColspan -= subColspan;

            subphaseRow.push(
              <td
                key={`subphase-${subphase.id}-${getRandomNumber(1, 150)}`}
                colSpan={subColspan}
                style={{
                  ...styles.cellStyle,
                  width: `${cellWidth * subColspan}px`,
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    ...styles.text,
                    width: `${cellWidth * subColspan-3}px`,
                    // maxWidth: `${cellWidth * subColspan}px`,
                  }}
                >
                  {subphase.name}
                </Typography>
              </td>
            );
            subphase.microcycles.forEach((microcycle: any, index: number) => {
              microcycleRow.push(
                <td
                  key={`microcycle-${microcycle.id}-${getRandomNumber(1, 150)}`}
                  style={{
                    ...styles.cellStyle,
                    width: `${cellWidth}px`,
                  }}
                  onClick={() => onRowPress(microcycle)}

                >
                  <Typography
                    noWrap
                    sx={{
                      ...styles.text,
                      cursor: "pointer"
                    }}
                  >
                    {index + 1}
                  </Typography>
                </td>
              );
            });
          });

          if (remainingColspan > 0) {
            subphaseRow.push(
              <td
                key={`remaining-subphase-${phase.id}-${getRandomNumber(1, 150)}`}
                colSpan={remainingColspan}
                style={{
                  ...styles.cellStyle,
                  width: `${cellWidth * remainingColspan}px`,
                }}
              >
                <Typography noWrap sx={styles.text}></Typography>
              </td>
            );

            microcycleRow.push(
              <td
                key={`remaining-microcycle-${phase.id}-${getRandomNumber(1, 150)}`}
                colSpan={remainingColspan}
                style={{
                  ...styles.cellStyle,
                  width: `${cellWidth * remainingColspan}px`,
                }}
              >
                <Typography noWrap sx={styles.text}></Typography>
              </td>
            );
          }
        } else {
          subphaseRow.push(
            <td
              key={`empty-subphase-${phase.id}-${getRandomNumber(150, 200)}`}
              colSpan={phaseColspan}
              style={{
                ...styles.cellStyle,
                width: `${cellWidth * phaseColspan}px`,
              }}
            >
              <Typography noWrap sx={styles.text}></Typography>
            </td>
          );

          microcycleRow.push(
            <td
              key={`empty-microcycle-${phase.id}-${getRandomNumber(1, 150)}`}
              colSpan={phaseColspan}
              style={{
                ...styles.cellStyle,
                width: `${cellWidth * phaseColspan}px`,
              }}
            >
              <Typography noWrap sx={styles.text}></Typography>
            </td>
          );
        }

        skipToIndex = phaseEndIndex + 1;
        continue;
      }

      phaseRow.push(
        <td key={`empty-phase-${i}-${getRandomNumber(200, 300)}`} style={styles.cellStyle}>
          <Typography noWrap sx={styles.text}></Typography>
        </td>
      );

      subphaseRow.push(
        <td key={`empty-subphase-${i}-${getRandomNumber(400, 500)}`} style={styles.cellStyle}>
          <Typography noWrap sx={styles.text}></Typography>
        </td>
      );

      microcycleRow.push(
        <td key={`empty-microcycle-${i}-${getRandomNumber(600, 700)}`} style={styles.cellStyle}>
          <Typography noWrap sx={styles.text}></Typography>
        </td>
      );
    }

    return { phaseRow, subphaseRow, microcycleRow };
  }




  const { phaseRow, subphaseRow, microcycleRow } = mapRows(weeks, dataPhases, cellWidth);


  return (
    <div style={styles.mainBox}>
      <table style={{ width: "max-content" }}>
        <tbody>
          {headerPhases.map((header: any, index: number) => (
            <tr key={index} style={{ height: header.height }}>
              <th style={styles.headerCell}>
                <Typography style={styles.headerText}>{header.text}</Typography>
              </th>
            </tr>
          ))}
        </tbody>
      </table>

      <table ref={tableRef} style={{ width: '100%' }}>
        {dataPhases.length === 0 || calendarData?.length === 0 ? (
          <tbody>
            <tr>
              <td
                style={{ ...styles.cellStyle, textAlign: "center" }}
                colSpan={1}
              >
                <Typography style={styles.noPhases}>
                  {i18n.plans.noPhases}
                </Typography>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>

            <tr>{phaseRow}</tr>
            <tr>{subphaseRow}</tr>
            <tr>{microcycleRow}</tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default PhaseTable;
