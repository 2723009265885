import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { LanguageContext } from "../context/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultPalette from "../theme/palette";
import {
  Avatar,
  IconButton,
  Modal,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import logo from "../resources/svg/logo.svg";
import CustomProfileBox from "./CustomProfileBox";
import DrawerComponent from "./DrawerComponent";
import { resetValuesWeekState } from "../reducers/plansSlice";
import { useAppDispatch } from "../hooks";
import SettingsPage from '../../pages/SettingsPage';
import CustomSettingsProfile from './CustomSettingsProfile';
import { getRandomNumber } from '../resources/simpleFunction';

const NavigationBar = (): React.ReactElement => {
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");

  const navigate = useNavigate();
  const [showCustomBox, setShowCustomBox] = useState(false);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const dispatch = useAppDispatch();
  const selectedNavItem = location.pathname;

  const redirect = async (path: string) => {
    if(path !== "/currentWeek") {
    navigate(path);
    }
    else {
      navigate(path,{state: {reload: getRandomNumber(1, 150)}});
    }
  };

  useEffect(() => {
    const onOpening = async () => {
      if (selectedNavItem === "/annualPlan") {
        await dispatch(resetValuesWeekState());
      }
    };
    onOpening();
  }, [selectedNavItem]);

  const handleAvatarClick = () => {
    setShowCustomBox((prevShow) => !prevShow);
  };

  const styles: any = {
    appBar: {
      boxShadow: "4px 5px 10px 0px rgba(38, 38, 38, 1)",
      backgroundColor: palette.custom.darkBlack,
      width: "100vw",
      height: "80px",
      alignSelf: "center",
      borderBottom: `1px solid ${palette.custom.gray}`,
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      width: "100vw",
      margin: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    name: {
      padding: "0px",
      height: "0px",
      paddingLeft: "3px",
      lineHeight: "35px",
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
      fontStyle: "normal",
    },
  };

  return (
    <AppBar sx={styles.appBar}>
      <Toolbar sx={styles.toolBar}>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                width: "25vw",
                paddingLeft: "2%",
                cursor: "pointer",
              }}
              onClick={() => redirect("/")}
            >
              <img src={logo} alt="Logo Icon" style={{ height: "30px" }} />
              <Typography variant="h6" style={styles.name}>
                {i18n.appData.name}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50vw",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {renderNavItem(i18n.appData.dashboard, "/")}
              {renderNavItem(
                i18n.appData.annualPlan,
                "/annualPlan",
                "/weeklyPlan"
              )}
              {renderNavItem(i18n.appData.weeklyPlan, "/currentWeek")}
              {/* {renderNavItem(i18n.appData.settings, "/settings")} */}
            </Box>
            <Box
              sx={{
                width: "25vw",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "1.2%",
              }}
            >
              <IconButton onClick={handleAvatarClick}>
                <Avatar />
              </IconButton>
              {showCustomBox && (
                <Modal
                  open={showCustomBox}
                  onClose={() => setShowCustomBox(false)}
                  disableAutoFocus
                >
                <CustomSettingsProfile />
                </Modal>
              )}
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );

  function renderNavItem(label: string, path: string, path2?: string) {
    return (
      <Typography
        variant="h5"
        component="div"
        onClick={() => redirect(path)}
        sx={{
          display: "center",
          paddingLeft: "2%",
          cursor: "pointer",
          color:
            selectedNavItem === path || selectedNavItem === path2
              ? palette.custom.orange
              : palette.custom.white,
        }}
      >
        {label}
      </Typography>
    );
  }
};

export default NavigationBar;
