import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  filterWeekPlan,
  getWeekSessionValues,
  selectPlan,
} from "../../reducers/plansSlice";
import {
  convertDateForFilterWeek,
  findWeekTime,
  getFormattedDate,
  getSeasonMonthsAndWeeks,
  getStartAndEndDateOfCurrentWeek,
  setCompetitionsToCalendar,
} from "../../resources/simpleFunction";
import DefaultPalette from "../../theme/palette";
import CustomWeeklyBody from "./CustomWeeklyBody";
import CustomModal from "../CustomModal";
import WeeklyDetailsData from "./WeeklyDetailsData";
import CustomMicrocycle from "./CustomMicrocycle";
import NewRow from "../NewRow";
import CircularIndeterminate from "../CircularIndeterminate";
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

interface Props {
  startDayTime: any;
  endDayTime: any;
  mc: any;
  reload: any;
  setReload: any;
  setStartDayTime: any;
  setEndDayTime: any;
  setMcNumber: any;
  setValueCurrent: any;
  calendarData: any;
}

const WeeklyTable = (props: Props): React.ReactElement => {
  const {
    startDayTime,
    endDayTime,
    mc,
    setReload,
    reload,
    setValueCurrent,
    setStartDayTime,
    setEndDayTime,
    setMcNumber,
    calendarData
  } = props;
  const { i18n } = useContext(LanguageContext);
  const annualPlans = useAppSelector(selectPlan);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [status, setStatus] = useState();
  const palette = DefaultPalette("dark", "custom");
  const location = useLocation()

  const headers1: any = [
    { text: i18n.plans.headers.monday },
    { text: i18n.plans.headers.tuesday },
    { text: i18n.plans.headers.wednesday },
    { text: i18n.plans.headers.thursday },
    { text: i18n.plans.headers.friday },
    { text: i18n.plans.headers.saturday },
    { text: i18n.plans.headers.sunday },
  ];

  useEffect(() => {
    let isActive = true;

    const onScreenOpening = async (): Promise<void> => {
      try {
        setLoading(true);
        if (isActive && annualPlans?.value?.id) {
          await dispatch(
            filterWeekPlan({
              id: annualPlans?.value?.id,
              start_week: convertDateForFilterWeek(startDayTime),
              end_week: convertDateForFilterWeek(endDayTime, true),
              startDayTime,
              endDayTime,
            })
          );
          setValueCurrent(annualPlans?.value?.id);
          if (isActive) setStatus(await findWeekTime(startDayTime, endDayTime));
          await dispatch(
            getWeekSessionValues({
              id: annualPlans?.value?.id,
              start_week: convertDateForFilterWeek(startDayTime),
              end_week: convertDateForFilterWeek(endDayTime, true),
            })
          );
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
      }
    };

    onScreenOpening();

    return () => {
      isActive = false;
    };
  }, [reload, annualPlans?.value?.id]);

  const onCycleClick = async (val: any) => {
    try {
      setMcNumber(val?.number);
      setStartDayTime(getFormattedDate(val.week_start_date));
      setEndDayTime(getFormattedDate(val.week_end_date));
      setReload((v: any) => !v);
    } catch (error) {}
  };


  useEffect(() => {
    let isActive = true;

    const onScreenOpening = async (): Promise<void> => {
      try {
        setLoading(true);
        const weeks = calendarData?.flatMap((monthData: any) => monthData.weeks)
        const { startDate } = getStartAndEndDateOfCurrentWeek();

        const start = format(startDate, "yyyy-MM-dd")

        const current = weeks.find((i: any) => i.week_start_date.startsWith(start))

        await onCycleClick(current ?? {})
        setLoading(false);

      } catch (error: any) {
        setLoading(false);
      }
    };

    location.state.reload && onScreenOpening();

    return () => {
      isActive = false;
    };
  }, [location.state.reload]);

  return (
    <div>
      {!loading && calendarData && (
        <CustomMicrocycle
          data={calendarData}
          mc={mc}
          onCycleClick={onCycleClick}
        />
      )}
      <NewRow height={"1rem"} />

      <div style={{ borderLeft: `1px solid ${palette.custom.background}` }}>
        {!loading && headers1 && annualPlans?.valuesWeek && (
          <CustomWeeklyBody
            headers={headers1}
            data={annualPlans?.valuesWeek}
            pastSession={annualPlans.weekSessionValues}
            setDetailsModal={setDetailsModal}
            status={status}
          />
        )}
        {loading && <CircularIndeterminate />}

        {detailsModal && annualPlans.valueWeek && (
          <CustomModal
            open={detailsModal}
            onClose={() => {
              setDetailsModal(false);
            }}
            text={
              annualPlans.valueWeek?.date_of_competition ? "Event" : "Training"
            }
          >
            <WeeklyDetailsData
              closeModal={() => {
                setDetailsModal(false);
              }}
              setReload={setReload}
            />
          </CustomModal>
        )}
      </div>
    </div>
  );
};

export default WeeklyTable;
