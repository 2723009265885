import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import CustomBlueButton from "./CustomBlueButton";
import { LanguageContext } from "../context/LanguageContext";
import DefaultPalette from "../theme/palette";
import NewRow from "./NewRow";

interface Props {
  onClickOne?: any;
  onClickTwo: any;
  variant: string;
  type?: string;
}

const CustomTwoButtons = (props: Props): React.ReactElement => {
  const { onClickOne, onClickTwo, variant, type } = props;
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");
  return (
    <Box sx={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
      {variant === "editDelete" && (
        <React.Fragment>
          {onClickOne && <CustomBlueButton
            onPress={onClickOne}
            label={
              type === "competition"
                ? i18n.plans.editCompetition
                : i18n.plans.editTraining
            }
          />}
          {onClickOne && <NewRow height={"10px"} />}

          <CustomBlueButton
            onPress={onClickTwo}
            label={
              type === "competition"
                ? i18n.plans.deleteCompetition
                : i18n.plans.deleteTraining
            }
            textColor={palette.custom.red}
            backgroundColor={palette.custom.white}
          />
        </React.Fragment>
      )}
      {variant === "deleteEvent" && (
        <React.Fragment>
          <Typography
            variant="body2"
            sx={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            {i18n.plans.deleteTextEvent}
          </Typography>
          <CustomBlueButton
            onPress={onClickOne}
            label={i18n.plans.delete}
            textColor={palette.custom.red}
            backgroundColor={palette.custom.white}
          />
          <NewRow height={"10px"} />
          <CustomBlueButton
            onPress={onClickTwo}
            label={i18n.plans.cancel}
            textColor={palette.custom.gray}
            backgroundColor={"transparent"}
            fontWeight={"normal"}
            textDecoration={"underline"}
          />
        </React.Fragment>
      )}
      {variant === "deleteTraining" && (
        <React.Fragment>
          <Typography
            variant="body2"
            sx={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
          >
            {i18n.plans.deleteTextTraining}
          </Typography>
          <CustomBlueButton
            onPress={onClickOne}
            label={i18n.plans.delete}
            textColor={palette.custom.red}
            backgroundColor={palette.custom.white}
          />
          <NewRow height={"10px"} />
          <CustomBlueButton
            onPress={onClickTwo}
            label={i18n.plans.cancel}
            textColor={palette.custom.gray}
            backgroundColor={"transparent"}
            fontWeight={"normal"}
            textDecoration={"underline"}
          />
        </React.Fragment>
      )}
      {variant === "addReport" && (
        <React.Fragment>
          <CustomBlueButton
            onPress={onClickOne}
            label={i18n.plans.addReport}
            textColor={palette.custom.blue}
            backgroundColor={palette.custom.white}
          />
          <NewRow height={"10px"} />
          <CustomBlueButton
            onPress={onClickTwo}
            label={i18n.plans.cancel}
            textColor={palette.custom.gray}
            backgroundColor={"transparent"}
            fontWeight={"normal"}
            textDecoration={"underline"}
          />
        </React.Fragment>
      )}
    </Box>
  );
};
export default CustomTwoButtons;
