import React from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import DefaultPalette from "../theme/palette";

interface Props {
  open: boolean;
  onClose?: any;
  text?: string;
  children?: any;
  width?: number
}

const CustomModal = (props: Props) => {
  const palette = DefaultPalette("dark", "custom");

  const style = {
    position: "absolute",
    maxHeight: "90vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.width ?? 400,
    bgcolor: "background.paper",
    border: `1px solid ${palette.custom.gray}`,
    borderRadius: "20px",
    overflowY: "auto",
    boxShadow: "none",
    p: "30px",
  };
  return (
    <Modal open={props.open} onClose={props?.onClose} disableAutoFocus={true}>
      <Box sx={style}>
        <Typography
          variant="h5"
          align="center"
          sx={{
            padding: "2rem",
            marginBottom: "1rem",
          }}
        >
          {props.text}
        </Typography>
        {props.children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
