import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { MainContext } from "../../context/MainContext";

import { Role, importanceDisplayCardObject } from "../../utils/enums";
import CustomTextLine from "../CustomTextLine";
import { format } from "date-fns";
import CustomTwoButtons from "../CustomTwoButtons";
import CompetitionForm from "../../../pages/forms/CompetitionForm";
import { useAppSelector } from "../../hooks";
import { selectPlan } from "../../reducers/plansSlice";
import { Box, Divider, Typography } from "@mui/material";
import DefaultPalette from "../../theme/palette";
import CustomAccordionItem from "../CustomAccordionItem";
import NewRow from "../NewRow";
import CompetitionReportForm from "../../../pages/forms/CompetitionReportForm";
import CustomBlueButton from "../CustomBlueButton";

interface Props {
  data: any;
  isPast: boolean;
  handleDeleteCompetition: any;
  closeModal: any;
  setReload: any;
}

const WeeklyCompetitionCard = (props: Props): React.ReactElement => {
  const { data, isPast, handleDeleteCompetition, closeModal, setReload } =
    props;
  const palette = DefaultPalette("dark", "custom");

  const { i18n } = useContext(LanguageContext);
  const { currentProfile } = useContext(MainContext);
  const annualPlans = useAppSelector(selectPlan);

  const [editCompetition, setEditCompetition] = useState(false);
  const [deleteCompetition, setDeleteCompetition] = useState(false);
  const [addReport, setAddReport] = useState(false);

  const cond =
    data &&
    data?.reports?.filter((el: any) => el.profile === currentProfile.id)
      ?.length;

  const isReports = data && data?.reports && data.reports?.length > 0;

  const futureCompetitionData = [
    {
      text: i18n.plans.competitionData.level,
      textValue: data?.name,
    },
    {
      text: i18n.plans.competitionData.location,
      textValue: data?.location,
    },
    {
      text: i18n.plans.competitionData.dateOfComp,
      textValue:
        data?.date_of_competition &&
        format(new Date(data?.date_of_competition), "dd. MM. yyyy."),
    },
    {
      text: i18n.plans.competitionData.importance,
      textValue: importanceDisplayCardObject[data?.importance],
    },
    {
      text: i18n.plans.competitionData.target,
      textValue: data?.target,
    },
  ];

  const pastCompetitionData = [
    {
      text: i18n.plans.competitionData.level,
      textValue: data?.name,
    },
    {
      text: i18n.plans.competitionData.location,
      textValue: data?.location,
    },
    {
      text: i18n.plans.competitionData.importance,
      textValue: importanceDisplayCardObject[data?.importance],
    },
    {
      text: i18n.plans.competitionData.target,
      textValue: data?.target,
    },
  ];

  const pastAthleteData = [
    {
      text: i18n.plans.competitionData.athlete,
      textValue: annualPlans.valueAthlete?.name,
    },
    {
      text: i18n.plans.competitionData.dateOfComp,
      textValue:
        data?.date_of_competition &&
        format(new Date(data?.date_of_competition), "dd. MM. yyyy."),
    },
  ];

  const reports =
    data &&
    data?.reports &&
    data.reports?.length > 0 &&
    data?.reports.map((el: any) => {
      return [
        {
          status: "out",
          text: i18n.plans.from,
          value: el?.profile_name !== "" ? el?.profile_name : "-",
        },
        {
          status: "in",
          text: i18n.plans.reportDetails,
          value: el.description !== "" ? el?.description : "-",
        },

        {
          status: "in",
          text: i18n.plans.physicalPreparation,
          value:
            el.physical_preparation !== "" ? el?.physical_preparation : "-",
        },
        {
          status: "in",
          text: i18n.plans.psychology,
          value: el.psychology !== "" ? el?.psychology : "-",
        },
        {
          status: "in",
          text: i18n.plans.technique,
          value: el.technique !== "" ? el?.technique : "-",
        },
        {
          status: "in",
          text: i18n.plans.tactic,
          value: el.tactic !== "" ? el?.tactic : "-",
        },
      ];
    });

  const displayLines =
    reports &&
    reports?.length > 0 &&
    reports.map((report: any, index: any) => {
      return (
        <React.Fragment key={index}>
          {report.map((el: any, innerIndex: any) => (
            <React.Fragment key={innerIndex}>
              {el.status === "out" ? (
                <CustomTextLine text={el.text} textValue={el.value} />
              ) : (
                <CustomAccordionItem text={el.text} description={el.value} />
              )}
              {el.status === "out" && <NewRow height={10} />}
            </React.Fragment>
          ))}
          <Divider sx={{ color: palette.custom.lightGrayWithOpacity }} />
        </React.Fragment>
      );
    });

  return (
    <div>
      {!editCompetition &&
        !isPast &&
        futureCompetitionData &&
        futureCompetitionData.map((val: any, idx: any) => {
          if (val.textValue) {
            return (
              <CustomTextLine
                key={idx}
                text={val.text}
                textValue={val.textValue}
              />
            );
          }
          return null;
        })}
      {!editCompetition &&
        !deleteCompetition &&
        !isPast &&
        currentProfile.role !== Role.Athlete && (
          <CustomTwoButtons
            variant="editDelete"
            type="competition"
            onClickOne={() => setEditCompetition(true)}
            onClickTwo={() => setDeleteCompetition(true)}
          />
        )}
      {editCompetition && !deleteCompetition && (
        <CompetitionForm
          edit={true}
          competition={data}
          annual={annualPlans.value}
          closeModal={() => {
            setEditCompetition(false);
          }}
        />
      )}
      {deleteCompetition && (
        <CustomTwoButtons
          variant="deleteEvent"
          type="competition"
          onClickOne={() => handleDeleteCompetition()}
          onClickTwo={() => setDeleteCompetition(false)}
        />
      )}
      {isPast && (
        <Box>
          {!deleteCompetition && pastAthleteData.map((val: any, idx: any) => {
            if (val.textValue) {
              return (
                <CustomTextLine
                  key={idx}
                  text={val.text}
                  textValue={val.textValue}
                />
              );
            }
            return null;
          })}
         {!deleteCompetition && <Divider sx={{ color: palette.custom.lightGray }} />}
          {!deleteCompetition && <Typography
            sx={{
              textAlign: "center",
              color: palette.custom.white,
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {i18n.plans.eventDetails}
          </Typography>}

          {!deleteCompetition && pastCompetitionData.map((val: any, idx: any) => {
            if (val.textValue) {
              return (
                <CustomTextLine
                  key={idx}
                  text={val.text}
                  textValue={val.textValue}
                />
              );
            }
            return null;
          })}
          {!deleteCompetition && <Divider sx={{ color: palette.custom.lightGray }} />}

          {!deleteCompetition && <Typography
            sx={{
              textAlign: "center",
              color: palette.custom.white,
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {i18n.plans.reports}
          </Typography>}

          {!deleteCompetition && !isReports && (
            <Typography
              sx={{
                textAlign: "center",
                color: palette.custom.white,
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              {i18n.plans.noCompReport}
            </Typography>
          )}

          {!deleteCompetition && addReport && (
            <CompetitionReportForm
              competition={annualPlans.valueWeek}
              closeModal={() => setAddReport(false)}
              setReload={setReload}
            />
          )}

          {!deleteCompetition && isReports && displayLines}

          {!deleteCompetition && cond === 0 && !addReport && (
            <CustomTwoButtons
              variant="addReport"
              onClickOne={() => setAddReport(true)}
              onClickTwo={closeModal}
            />
          )}

    {!editCompetition &&
        !deleteCompetition &&
        currentProfile.role !== Role.Athlete && (
          <CustomTwoButtons
            variant="editDelete"
            type="competition"
            onClickTwo={() => setDeleteCompetition(true)}
          />
        )}

          {!deleteCompetition && !addReport && isReports && cond !== 0 && (
            <React.Fragment>
              <NewRow height={"20px"} />
              <CustomBlueButton
                onPress={closeModal}
                label={i18n.plans.cancel}
                textColor={palette.custom.gray}
                backgroundColor={"transparent"}
                fontWeight={"normal"}
                textDecoration={"underline"}
              />
            </React.Fragment>
          )}
        </Box>
      )}
    </div>
  );
};
export default WeeklyCompetitionCard;
