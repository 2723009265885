import React, { useContext, useState } from "react";

import * as yup from "yup";

import CustomForm from "../../app/components/CustomForm";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../app/context/LanguageContext";
import {
  postTrainingTemplate,
  selectPlan,
} from "../../app/reducers/plansSlice";

interface Props {
  closeModal?: any;
}

const CreateTemplateForm = (props: Props): React.ReactElement => {
  const { closeModal } = props;
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { i18n } = useContext(LanguageContext);
  const annualPlans = useAppSelector(selectPlan);

  const fields = [
    {
      name: "name",
      label: i18n.plans.nameTemplate,
      type: "text",
      placeholder: i18n.plans.nameTemplate,
      edit: false,
    },
  ];

  function extractTrainingData(data: any) {
    const result: any = [];

    data.forEach((dayData: any, index: number) => {
      dayData.objects.forEach((training: any) => {
        result.push({
          training_id: training.id,
          day_index: index,
        });
      });
    });

    return result;
  }

  const init = {
    name: "",
  };

  const scheme = yup.object({
    name: yup.string().required(i18n.plans.requiredField),
  });

  const onFormSubmit = async (values: any) => {
    try {
      const trainings = extractTrainingData(annualPlans?.valuesWeek);
      setDisabled(true);
      const formData = {
        ...values,
        training_days: trainings,
      };

      if (trainings?.length > 0) {
        let res;

        res = await dispatch(postTrainingTemplate(formData));

        if (res?.meta.requestStatus === "fulfilled") {
          setSuccess(i18n.auth.success.successSaveTemplate);
        } else {
          setError(i18n.auth.errors.error);
        }
      } else {
        setError(i18n.auth.errors.dontHaveTrainings);
      }

      setDisabled(false);
    } catch (error) {
      setDisabled(false);
    }
  };

  return (
    <>
      {fields && fields?.length > 0 && (
        <CustomForm
          formFields={fields}
          initialValues={init}
          validationSchema={scheme}
          onFormSubmit={onFormSubmit}
          disabled={disabled || success !== ""}
          text={'Save'}
          // cancelFunction={closeModal}
        />
      )}
      {error !== "" && (
        <Typography variant="subtitle1" color={"error"} paddingTop={2}>
          {error}
        </Typography>
      )}
      {success !== "" && (
        <Typography variant="subtitle1" color={"success"} paddingTop={2}>
          {success}
        </Typography>
      )}
    </>
  );
};
export default CreateTemplateForm;
