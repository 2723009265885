import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../app/context/MainContext";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getAnnualPlan,
  getAnnualPlans,
  getWeekSessionValues,
  selectPlan,
} from "../app/reducers/plansSlice";
import CustomModal from "../app/components/CustomModal";
import CompetitionForm from "./forms/CompetitionForm";
import { Box, Dialog } from "@mui/material";
import CustomBlueButton from "../app/components/CustomBlueButton";
import { Role } from "../app/utils/enums";
import { LanguageContext } from "../app/context/LanguageContext";
import DefaultPalette from "../app/theme/palette";
import CircularIndeterminate from "../app/components/CircularIndeterminate";
import NewRow from "../app/components/NewRow";
import AnnualPlanPicker from "../app/components/annual/AnnualPlanPicker";
import AnnualTable from "../app/components/annual/AnnualTable";
import AnnualGraph from "../app/components/annual/AnnualGraph";
import EmptyComponent from "../app/components/EmptyComponent";
import { ListAlt } from "@mui/icons-material";
import PhaseSubPhaseForm from "./forms/PhaseSubPhaseForm";
import PhaseTable from "../app/components/annual/PhaseTable";
import {
  getSeasonMonthsAndWeeks,
  setCompetitionsToCalendar,
} from "../app/resources/simpleFunction";

const AnnualPlanPage = (): React.ReactElement => {
  const { currentProfile } = useContext(MainContext);
  const { i18n } = useContext(LanguageContext);
  const palette = DefaultPalette("dark", "custom");

  const [eventModal, setEventModal] = useState(false);
  const [phaseModalIsOpen, setPhaseModalIsOpen] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loadValues, setLoadValues] = useState<any>();
  const annualPlans = useAppSelector(selectPlan);
  const dispatch = useAppDispatch();
  const [calendarData, setCalendarData] = useState<any>([]);
  const [weeks, setWeeks] = useState<any>([]);

  const openPhaseModal = (phase: any) => {
    setSelectedPhase(phase);
    setPhaseModalIsOpen(true);
  };

  const onSelect = async (selectedItem: any) => {
    try {
      setLoading(true);
      let res = await dispatch(getAnnualPlan(selectedItem));
      if (res.meta.requestStatus === "fulfilled") {
        const loads = await dispatch(
          getWeekSessionValues({
            id: res.payload?.id,
            start_week: `${res.payload?.season?.start_date}T00:00:00`,
            end_week: `${res.payload?.season?.end_date}T23:59:59`,
          })
        );
        if (loads.meta.requestStatus === "fulfilled")
          if (loads?.payload.length > 0) {
            setLoadValues(loads?.payload.map((v: any) => v.load));
          } else {
            setLoadValues([]);
          }
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isActive = true;

    const onScreenOpening = async (): Promise<void> => {
      try {
        setLoading(true);
        if (isActive) {
          await dispatch(getAnnualPlans());
          if (annualPlans?.value?.id) {
            await onSelect(annualPlans?.value?.id);
          }
        }
      } catch (error: any) {
        console.error("Error in onScreenOpening:", error);
      } finally {
        setLoading(false);
      }
    };
    onScreenOpening();

    return () => {
      isActive = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let isActive = true;

    const onOpening = async (): Promise<void> => {
      try {
        if (
          annualPlans?.value?.season?.start_date &&
          annualPlans?.value?.season?.end_date
        ) {
          const calendarResult = getSeasonMonthsAndWeeks(
            annualPlans?.value?.season?.start_date,
            annualPlans?.value?.season?.end_date
          );

          if (isActive) {
            const updatedCalendar = await setCompetitionsToCalendar(
              calendarResult,
              annualPlans?.valuesCompetition
            );
            const data: any = await Promise.all(updatedCalendar);
            let w = 0;
            for (let i = 0; i < data?.length; i++) {
              w += data[i]?.weeks?.length;
            }

            setCalendarData(data);
            setWeeks(w);
          }
        }
      } catch (error: any) {
        console.error("Error setting calendar data:", error);
      }
    };

    onOpening();

    return () => {
      isActive = false;
    };
  }, [annualPlans?.value?.id, annualPlans?.valuesCompetition]);


  const styles = {
    textStyle: {
      fontSize: "18px",
      color: palette.custom.lightGray,
    },
    textChooseAthelete: {
      fontSize: "26px",
      color: palette.custom.lightGray,
      marginTop: 30,
    },
    boxRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100vw",
      margin: "auto",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <NewRow height={"90px"} />
      <Box sx={styles.boxRow}>
        <Box
          sx={{
            display: "flex",
            width: "50vw",
          }}
        >
          <AnnualPlanPicker
            annualPlans={annualPlans}
            onSelect={onSelect}
            currentProfile={currentProfile}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "50vw",
          }}
        >
          {annualPlans?.value?.id !== undefined &&
            annualPlans?.valueSeason?.id &&
            currentProfile.role !== Role.Athlete && (
              <CustomBlueButton
                onPress={() => {
                  setEventModal(true);
                }}
                label={i18n.plans.addEvent}
                width={"40%"}
              />
            )}
          {annualPlans?.value?.id !== undefined &&
            annualPlans?.valueSeason?.id &&
            currentProfile.role !== Role.Athlete && (
              <CustomBlueButton
                onPress={() => {
                  setPhaseModalIsOpen(true);
                  setSelectedPhase(null)
                }}
                label={i18n.appData.phase}
                width={"40%"}
              />
            )}
        </Box>
      </Box>

      {!loading && (
        <>
          {annualPlans?.value.id === undefined && (
            <EmptyComponent
              paddingTop="5rem"
              fontSize={"h4"}
              text={i18n.appData.noAnnualPlan}
              icon={
                <ListAlt
                  sx={{ fontSize: "3rem", color: palette.custom.gray }}
                />
              }
            />
          )}
          {annualPlans?.value?.id !== undefined &&
            calendarData.length > 0 &&
            weeks > 0 && (
              <AnnualTable calendarData={calendarData} weeks={weeks} />
            )}
          <NewRow height={"15px"} />
          {annualPlans?.value?.phases && calendarData.length > 0 && (
            <PhaseTable
              dataPhases={annualPlans?.value?.phases}
              calendarData={calendarData}
              onPhaseClick={openPhaseModal}
            />
          )}
          <NewRow height={"15px"} />
          {annualPlans?.weekSessionValues && loadValues && (
            <AnnualGraph dataGraph={loadValues ?? []} />
          )}
        </>
      )}
      {loading && <CircularIndeterminate />}
      {eventModal && (
        <CustomModal
          open={eventModal}
          onClose={() => {
            setEventModal(false);
          }}
          text={i18n.plans.addEvent}
        >
          <CompetitionForm
            annual={annualPlans.value}
            closeModal={() => {
              setEventModal(false);
            }}
          />
        </CustomModal>
      )}
      {phaseModalIsOpen && (
        <CustomModal
          open={phaseModalIsOpen}
          onClose={() => setPhaseModalIsOpen(false)}
          text={
            selectedPhase
              ? i18n.appData.editPhase 
              : i18n.appData.addPhase  
          }
          width={800}
        >
          <PhaseSubPhaseForm
            annualPlanId={annualPlans?.value?.id}
            season={annualPlans?.value?.season}
            phase={selectedPhase}
            onClose={() => setPhaseModalIsOpen(false)}
          />
        </CustomModal>
      )}


    </div>
  );
};

export default AnnualPlanPage;
