import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import { useAppSelector } from "../../hooks";
import { selectPlan } from "../../reducers/plansSlice";
import DefaultPalette from "../../theme/palette";
import CustomAnnualBodyTable from "./CustomAnnualBodyTable";
import NewRow from "../NewRow";
import "../weekly/CustomWeeklyBody.css";

interface Props {
  calendarData?: any[];
  weeks?: number;
}

const AnnualTable = (props: Props): React.ReactElement => {
  const { calendarData, weeks } = props;

  const { i18n } = useContext(LanguageContext);
  const annualPlans = useAppSelector(selectPlan);
  const palette = DefaultPalette("dark", "custom");

  const headerAnnual = [
    {
      text: i18n.plans.headersAnnual.month,
      height: "40px",
    },
    {
      text: i18n.plans.headersAnnual.week,
      height: "120px",
    },
    {
      text: i18n.plans.headersAnnual.microcycle,
      height: "40px",
    },
    {
      text: i18n.plans.headersAnnual.events,
      height: "180px",
    },
    {
      text: i18n.plans.headersAnnual.importance,
      height: "40px",
    },
    {
      text: i18n.plans.headersAnnual.target,
      height: "40px",
    },
  ];

  const styles = {
    headerCell: {
      border: `1px solid ${palette.custom.background}`,
      backgroundColor: palette.custom.lightGray,
      paddingLeft: "5px",
    },
    headerText: {
      color: palette.custom.white,
      fontWeight: "bold",
    },
  };

  return (
    <div>
      <NewRow height={"15px"} />
      {calendarData && calendarData.length > 0 && annualPlans?.value?.id && (
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <table className="annualTable" style={{ width: "max-content" }}>
              <tbody>
                {headerAnnual &&
                  headerAnnual.map((header: any, index: any) => {
                    return (
                      <tr key={index} style={{ height: header?.height }}>
                        <th style={styles.headerCell}>
                          <Typography style={styles.headerText}>
                            {header?.text}
                          </Typography>
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div
              style={{
                overflowX: "auto",
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              {calendarData && calendarData.length > 0 && calendarData?.map((monthData: any, index: any) => {
                return (
                  <CustomAnnualBodyTable
                    monthData={monthData}
                    calendarData={calendarData}
                    weeks={weeks}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnualTable;
